export const userStatusInTheTeam = {
  STATUS_ACTIVE: 1,
  STATUS_ARCHIVED: 2,
}

export const userUsingType = {
  INDEFINITELY: 0,
  SOLO: 1,
  TEAM: 2,
}

export const taskViewReminderConstants = {
  period: [1, 2, 3, 4, 5],
  endRepeatCondition: [1, 2],
}

export const routeNamesBySpace = {
  CALENDAR_AND_LIST: [
    'home',
    'project-calendar',
    'home-task-list',
    'project-list',
    'ws-home',
    'ws-project-calendar',
    'ws-home-task-list',
    'ws-project-list',
  ],
  BOARD: [
    'project-board',
    'project-board-view',
    'ws-project-board',
    'ws-project-board-view',
  ],
}
export const routeNamesByTaskViewType = {
  CALENDAR: [
    'home',
    'project-calendar',
    'ws-home',
    'ws-project-calendar',
  ],
  LIST: [
    'home-task-list',
    'project-list',
    'ws-home-task-list',
    'ws-project-list',
  ],
  BOARD: [
    'project-board',
    'project-board-view',
    'ws-project-board',
    'ws-project-board-view',
  ],
}
