<template>
  <div class="app-body" @mouseenter="restartAbilityToDragscroll">
      <TheHeader :isMobile="isMobile" />

      <div v-show="/*!chooseServiceModalOpened*/ true" class="app-view">
        <TheNav v-show="!isMobile"/>

        <div class="app-page" ref="routerView">
          <RouterView />
        </div>
      </div>
  </div>
</template>

<script>
import { mapState, /*mapGetters, */mapMutations, mapActions } from 'vuex'

import { userUsingType } from '@/utils/constants'
import { currentWorkspaceId } from '@/mixins/workspace'
import { initWorkspaces, createWebSocket } from '@/mixins/views/layoutDefault'

import TheHeader from '@/components/TheHeader'
import TheNav from '@/components/nav/TheNav'

export default {
  name: 'DefaultLayout',
  components: {
    TheHeader,
    TheNav,
  },
  mixins: [
    currentWorkspaceId,
    initWorkspaces,
    createWebSocket,
  ],

  data: () => ({
    titleIntervalId: null,
    isCurrentTitle: true,
    currentTitle: null,
    spaceIsPressed: false,
    isMobile: false,
  }),
  computed: {
    ...mapState('user', [
      'user',
      'isTabActive',
    ]),

    showUserWelcome () {
      return !Object.values(userUsingType).includes(this.user?.usingType)
    },
    loadingComplete () {
      return this.user &&
        !!this.workspaces.length &&
        !!this.currentWorkspaceId &&
        !!this.projects
    },
  },

  updated () {
    this.setIsMobile()
  },

  async created () {
    this.setIsMobile()
    window.addEventListener('resize', this.setIsMobile)
    await Promise.all([
      this.getUserData(),
    ])
  },
  beforeDestroy () {
    if (this.titleIntervalId) {
      clearInterval(this.titleIntervalId)
    }
    window.removeEventListener('resize', this.setIsMobile)
  },

  methods: {
    ...mapMutations('general', [
      'SET_ABILITY_TO_DRAGSCROLL',
    ]),

    ...mapActions('user', [
      'getUserData',
      'setIsTabActive',
    ]),

    ...mapActions('bonus', [
      'getBonus',
    ]),

    ...mapMutations('services', [
      'HIDE_CHOOSE_SERVICE_MODAL',
    ]),

    ...mapActions('workspace', [
      'getWorkspaces',
      'initLocalStorageWorkspace',
      'setWorkspace',
    ]),

    ...mapMutations('task', [
      'APPEND_TASK',
      'REPLACE_TASK',
      'REMOVE_TASK',
    ]),
    ...mapActions('task', [
      'getAdditional',
    ]),

    ...mapMutations('notification', [
      'ADD_FEED',
    ]),

    ...mapMutations('pushNotification', [
      'SET_TAB_NOTIFICATION',
    ]),
    ...mapActions('pushNotification', [
      'notifyUser',
    ]),

    setIsMobile () {
      if (((window.innerWidth < 800) && (this.$route.path.search('/settings') == -1)) ||
        (this.$route.name.search('Chart') != -1) || (this.$route.path.search('/a/') != -1)) {
        this.isMobile = true
      } else this.isMobile = false
    },

    isValidBonusURL () {
      return this.$route.name === 'bonus-gift' && this.$route.query.code
    },
    openBonusModal () {
      if (this.bonus !== null && this.isValidBonusURL()) {
        this.$modal.show('bonus-modal')
      }
    },
    async checkBonusURL () {
      await this.getBonus(this.isValidBonusURL() ? this.$route.query.code : '')
    },
    watchForTabActive () {
      let hidden = 'hidden'
      let visibilityChange = 'visibilitychange'

      if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden'
        visibilityChange = 'msvisibilitychange'
      } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden'
        visibilityChange = 'webkitvisibilitychange'
      }

      const handleVisibilityChange = () => {
        this.setIsTabActive(!document[hidden])
      }

      document.addEventListener(visibilityChange, handleVisibilityChange, false)
    },
    setAbilityToDragscrollBySpace (event, bool) {
      this.spaceIsPressed = bool

      if (event.code === 'Space') {
        this.SET_ABILITY_TO_DRAGSCROLL(bool)
      } else if (this.abilityToDragscroll) {
        this.SET_ABILITY_TO_DRAGSCROLL(false)
      }
    },
    restartAbilityToDragscroll () {
      this.SET_ABILITY_TO_DRAGSCROLL(false)
    },
    toBlurActiveElement () {
      if (this.spaceIsPressed) {
        document.activeElement.blur()
      }
    },
  },

  metaInfo () {
    const vm = this

    return {
      changed (newInfo) {
        vm.currentTitle = newInfo.title
      },
    }
  },
  i18n: {
    messages: {
      'en': {
        'You have a new notification': 'You have a new notification',
      },
      'ru': {
        'You have a new notification': 'У вас новое уведомление',
      },
    },
  },
}
</script>

<style lang="scss">
.app-body {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
}

.app-view {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  // если не писать эту директиву, то при переходе с битой страницы,
  // как например `/get-trial-tariff` или `/task/4487836` или `/asdfasdf`, увидим поехавший дизайн календаря
  flex-direction: row;
}

.app-page {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
}
</style>
