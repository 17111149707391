import { mapActions } from 'vuex'
// import Echo from 'laravel-echo'
/*import { apiUrl, wsUrl, wsHost, wsPort } from '@/apiConfig'*/
// import axios from 'axios'

export const initWorkspaces = {
  methods: {
    ...mapActions('workspace', [
      'getWorkspaces',
    ]),

    async initWorkspaces () {
      // await this.getWorkspaces()
      // this.redirectToCorrectWorkspace()
      // this.initCurrentWorkspace()
    },
    redirectToCorrectWorkspace () {
      const foundWs = this.getWorkspace(+this.currentWorkspaceId)

      if (!foundWs) {
        const wsId = this.workspaces[0].id

        this.$router.push({
          name: 'ws-home',
          params: { wsId },
        })
      }
    },
    initCurrentWorkspace () {
      if (!this.currentWorkspaceId) {
        this.setWorkspace({
          workspace_id: this.workspaces[0].id,
          redirect: false,
        })
      }
    },
  },
}

export const createWebSocket = {
  methods: {
    createWebSocket () {
      /*window.EchoObj = new Echo({
        broadcaster: 'pusher',
        key: 'notify',
        host: wsUrl,
        authEndpoint: `${apiUrl}/broadcasting/auth`,
        wsHost: wsHost,
        wsPort,
        wssPort: wsPort,
        encrypted: wsPort === 443,
        forceTLS: wsPort === 443,
        disableStats: true,
        authorizer: channel => ({
          authorize: async (socketId, callback) => {
            // try {
            //   const options = {
            //     channel_name: channel.name,
            //     socket_id: socketId
            //   }
            //   const authData = await axios.post(`${apiUrl}/broadcasting/auth`, options)
            //
            //   // eslint-disable-next-line standard/no-callback-literal
            //   callback(false, authData.data)
            // } catch (error) {
            //   callback(true)
            // }
          },
        }),
      })*/
    },
  },
}
